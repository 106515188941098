/*--------------------------------

Grayic Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: 'Grayic';
  src: url('../fonts/Grayic.eot');
  src: url('../fonts/Grayic.eot') format('embedded-opentype'), url('../fonts/Grayic.woff2') format('woff2'), url('../fonts/Grayic.woff') format('woff'), url('../fonts/Grayic.ttf') format('truetype'), url('../fonts/Grayic.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/
.icon {
  display: inline-block;
  font: normal normal normal 1em/1 'Grayic';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------------------------
  change icon size
-------------------------*/
/* relative units */
.icon-sm {
  font-size: 0.8em;
}

.icon-lg {
  font-size: 1.2em;
}

/* absolute units */
.icon-16 {
  font-size: 16px;
}

.icon-32 {
  font-size: 32px;
}

/*----------------------------------
  add a square/circle background
-----------------------------------*/
.icon-bg-square,
.icon-bg-circle {
  padding: 0.35em;
  background-color: #eee;
}

.icon-bg-circle {
  border-radius: 50%;
}

/*------------------------------------
  use icons as list item markers
-------------------------------------*/
.icon-ul {
  padding-left: 0;
  list-style-type: none;
}

.icon-ul>li {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
}

.icon-ul>li>.icon {
  margin-right: 0.4em;
  line-height: inherit;
}

/*------------------------
  spinning icons
-------------------------*/
.icon-is-spinning {
  -webkit-animation: icon-spin 2s infinite linear;
  -moz-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*------------------------
  rotated/flipped icons
-------------------------*/
.icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.icon-flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.icon-flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}

/*------------------------
	icons
-------------------------*/

.icon-tail-right::before {
  content: "\ea02";
}

.icon-tail-left::before {
  content: "\ea03";
}

.icon-small-up::before {
  content: "\ea04";
}

.icon-small-down::before {
  content: "\ea05";
}

.icon-small-right::before {
  content: "\ea06";
}

.icon-small-left::before {
  content: "\ea07";
}

.icon-triangle-right-17::before {
  content: "\ea08";
}

.icon-card-update::before {
  content: "\ea09";
}

.icon-tie-01::before {
  content: "\ea0a";
}

.icon-puzzle-10::before {
  content: "\ea0b";
}

.icon-plug::before {
  content: "\ea0c";
}

.icon-laptop-71::before {
  content: "\ea0d";
}

.icon-goal-65::before {
  content: "\ea0e";
}

.icon-handshake::before {
  content: "\ea0f";
}

.icon-archery-target::before {
  content: "\ea10";
}

.icon-connect::before {
  content: "\ea11";
}

.icon-bulb-63::before {
  content: "\ea12";
}

.icon-chess-knight::before {
  content: "\ea13";
}

.icon-shoe-woman::before {
  content: "\ea14";
}

.icon-plug-2::before {
  content: "\ea15";
}

.icon-roadmap::before {
  content: "\ea16";
}

.icon-roadmap-2::before {
  content: "\ea17";
}

.icon-cards::before {
  content: "\ea18";
}

.icon-cards-2::before {
  content: "\ea19";
}

.icon-mirror-2::before {
  content: "\ea1a";
}

.icon-layers-3::before {
  content: "\ea1b";
}

.icon-layers-3-2::before {
  content: "\ea1c";
}

.icon-code::before {
  content: "\ea1d";
}

.icon-code-2::before {
  content: "\ea1e";
}

.icon-code-editor::before {
  content: "\ea1f";
}

.icon-book-open-2::before {
  content: "\ea20";
}

.icon-app::before {
  content: "\ea21";
}

.icon-3d-model::before {
  content: "\ea22";
}

.icon-3d-model-2::before {
  content: "\ea23";
}

.icon-app-2::before {
  content: "\ea24";
}

.icon-apple::before {
  content: "\ea25";
}

.icon-android::before {
  content: "\ea26";
}

.icon-apple-2::before {
  content: "\ea27";
}

.icon-android-2::before {
  content: "\ea28";
}

.icon-palette::before {
  content: "\ea29";
}

.icon-palette-2::before {
  content: "\ea2a";
}

.icon-leaf-80::before {
  content: "\ea2b";
}

.icon-leaf-80-2::before {
  content: "\ea2c";
}

.icon-battery-83::before {
  content: "\ea2d";
}

.icon-battery-83-2::before {
  content: "\ea2e";
}

.icon-drop::before {
  content: "\ea2f";
}

.icon-drop-2::before {
  content: "\ea30";
}

.icon-popcorn::before {
  content: "\ea31";
}

.icon-bowling-pins::before {
  content: "\ea32";
}

.icon-popcorn-2::before {
  content: "\ea33";
}

.icon-bowling-pins-2::before {
  content: "\ea34";
}

.icon-theater::before {
  content: "\ea35";
}

.icon-theater-2::before {
  content: "\ea36";
}

.icon-music::before {
  content: "\ea37";
}

.icon-brush::before {
  content: "\ea38";
}

.icon-music-2::before {
  content: "\ea39";
}

.icon-brush-2::before {
  content: "\ea3a";
}

.icon-soup::before {
  content: "\ea3b";
}

.icon-soup-2::before {
  content: "\ea3c";
}

.icon-energy::before {
  content: "\ea3d";
}

.icon-energy-2::before {
  content: "\ea3e";
}

.icon-cupcake::before {
  content: "\ea3f";
}

.icon-cupcake-2::before {
  content: "\ea40";
}

.icon-watermelon::before {
  content: "\ea41";
}

.icon-watermelon-2::before {
  content: "\ea42";
}

.icon-opening-times::before {
  content: "\ea43";
}

.icon-mug::before {
  content: "\ea44";
}

.icon-pizza-slice::before {
  content: "\ea45";
}

.icon-opening-times-2::before {
  content: "\ea46";
}

.icon-mug-2::before {
  content: "\ea47";
}

.icon-pizza-slice-2::before {
  content: "\ea48";
}

.icon-bell-53::before {
  content: "\ea49";
}

.icon-bell-53-2::before {
  content: "\ea4a";
}

.icon-pin-3::before {
  content: "\ea4b";
}

.icon-pin-3-2::before {
  content: "\ea4c";
}

.icon-gps::before {
  content: "\ea4d";
}

.icon-gps-2::before {
  content: "\ea4e";
}

.icon-face-recognition::before {
  content: "\ea4f";
}

.icon-sound::before {
  content: "\ea50";
}

.icon-microphone::before {
  content: "\ea51";
}

.icon-business-agent::before {
  content: "\ea52";
}

.icon-cctv::before {
  content: "\ea53";
}

.icon-home::before {
  content: "\ea54";
}

.icon-office::before {
  content: "\ea55";
}

.icon-key::before {
  content: "\ea56";
}

.icon-handshake-2::before {
  content: "\ea57";
}

.icon-home-2::before {
  content: "\ea58";
}

.icon-cctv-2::before {
  content: "\ea59";
}

.icon-fav-property::before {
  content: "\ea5a";
}

.icon-fav-property-2::before {
  content: "\ea5b";
}

.icon-key-2::before {
  content: "\ea5c";
}

.icon-business-agent-2::before {
  content: "\ea5d";
}

.icon-bookmark-2::before {
  content: "\ea5e";
}

.icon-bookmark-2-2::before {
  content: "\ea5f";
}

.icon-pen-01::before {
  content: "\ea60";
}

.icon-pen-01-2::before {
  content: "\ea61";
}

.icon-hat-3::before {
  content: "\ea62";
}

.icon-hat-3-2::before {
  content: "\ea63";
}

.icon-tag::before {
  content: "\ea64";
}

.icon-tag-2::before {
  content: "\ea65";
}

.icon-gift-2::before {
  content: "\ea66";
}

.icon-gift-2-2::before {
  content: "\ea67";
}

.icon-receipt-list-43::before {
  content: "\ea68";
}

.icon-receipt-list-43-2::before {
  content: "\ea69";
}

.icon-maestro::before {
  content: "\ea6a";
}

.icon-maestro-2::before {
  content: "\ea6b";
}

.icon-cart-simple::before {
  content: "\ea6c";
}

.icon-cart-simple-2::before {
  content: "\ea6d";
}

.icon-bag-09::before {
  content: "\ea6e";
}

.icon-bag-09-2::before {
  content: "\ea6f";
}

.icon-bicep::before {
  content: "\ea70";
}

.icon-bicep-2::before {
  content: "\ea71";
}

.icon-cycling::before {
  content: "\ea72";
}

.icon-cycling-2::before {
  content: "\ea73";
}

.icon-distance::before {
  content: "\ea74";
}

.icon-distance-2::before {
  content: "\ea75";
}

.icon-trophy::before {
  content: "\ea76";
}

.icon-trophy-2::before {
  content: "\ea77";
}

.icon-cpu::before {
  content: "\ea78";
}

.icon-cpu-2::before {
  content: "\ea79";
}

.icon-voice-recognition::before {
  content: "\ea7a";
}

.icon-voice-recognition-2::before {
  content: "\ea7b";
}

.icon-phone-charging-3::before {
  content: "\ea7c";
}

.icon-phone-charging-3-2::before {
  content: "\ea7d";
}

.icon-phone-button::before {
  content: "\ea7e";
}

.icon-pc-monitor::before {
  content: "\ea7f";
}

.icon-headphones::before {
  content: "\ea80";
}

.icon-media-player::before {
  content: "\ea81";
}

.icon-screen-rotation::before {
  content: "\ea82";
}

.icon-screen-rotation-2::before {
  content: "\ea83";
}

.icon-headphones-2::before {
  content: "\ea84";
}

.icon-headphones-2-2::before {
  content: "\ea85";
}

.icon-headphones-2-3::before {
  content: "\ea86";
}

.icon-pc-monitor-2::before {
  content: "\ea87";
}

.icon-media-player-2::before {
  content: "\ea88";
}

.icon-touch-id::before {
  content: "\ea89";
}

.icon-touch-id-2::before {
  content: "\ea8a";
}

.icon-bike-bmx::before {
  content: "\ea8b";
}

.icon-bike-bmx-2::before {
  content: "\ea8c";
}

.icon-airplane::before {
  content: "\ea8d";
}

.icon-airplane-2::before {
  content: "\ea8e";
}

.icon-drink-2::before {
  content: "\ea8f";
}

.icon-drink-2-2::before {
  content: "\ea90";
}

.icon-verified::before {
  content: "\ea91";
}

.icon-verified-2::before {
  content: "\ea92";
}

.icon-at-sign::before {
  content: "\ea93";
}

.icon-at-sign-2::before {
  content: "\ea94";
}

.icon-zoom-2::before {
  content: "\ea95";
}

.icon-phone-2::before {
  content: "\ea96";
}

.icon-trash-simple::before {
  content: "\ea97";
}

.icon-simple-remove::before {
  content: "\ea98";
}

.icon-send-2::before {
  content: "\ea99";
}

.icon-signal-2::before {
  content: "\ea9a";
}

.icon-link-69::before {
  content: "\ea9b";
}

.icon-lock::before {
  content: "\ea9c";
}

.icon-unlocked::before {
  content: "\ea9d";
}

.icon-phone-2-2::before {
  content: "\ea9e";
}

.icon-fav-remove::before {
  content: "\ea9f";
}

.icon-chat-round::before {
  content: "\eaa0";
}

.icon-chat-46::before {
  content: "\eaa1";
}

.icon-chat-33::before {
  content: "\eaa2";
}

.icon-chat-45::before {
  content: "\eaa3";
}

.icon-attach-87::before {
  content: "\eaa4";
}

.icon-chart-bar-52::before {
  content: "\eaa5";
}

.icon-chat-round-content::before {
  content: "\eaa6";
}

.icon-check-2::before {
  content: "\eaa7";
}

.icon-pen::before {
  content: "\eaa8";
}

.icon-heart-2::before {
  content: "\eaa9";
}

.icon-fav-remove-2::before {
  content: "\eaaa";
}

.icon-ui-04::before {
  content: "\eaab";
}

.icon-phone-call::before {
  content: "\eaac";
}

.icon-lock-2::before {
  content: "\eaad";
}

.icon-unlocked-2::before {
  content: "\eaae";
}

.icon-heart-2-2::before {
  content: "\eaaf";
}

.icon-email-83::before {
  content: "\eab0";
}

.icon-pen-2::before {
  content: "\eab1";
}

.icon-check-2-2::before {
  content: "\eab2";
}

.icon-chat-round-2::before {
  content: "\eab3";
}

.icon-chat-round-content-2::before {
  content: "\eab4";
}

.icon-chat-33-2::before {
  content: "\eab5";
}

.icon-chat-45-2::before {
  content: "\eab6";
}

.icon-chart-bar-52-2::before {
  content: "\eab7";
}

.icon-bold-remove::before {
  content: "\eab8";
}

.icon-attach-87-2::before {
  content: "\eab9";
}

.icon-check-simple::before {
  content: "\eaba";
}

.icon-menu-dots::before {
  content: "\eabb";
}

.icon-settings-gear-64::before {
  content: "\eabc";
}

.icon-settings-gear-64-2::before {
  content: "\eabd";
}

.icon-settings::before {
  content: "\eabe";
}

.icon-settings-2::before {
  content: "\eabf";
}

.icon-trash::before {
  content: "\eac0";
}

.icon-time-3::before {
  content: "\eac1";
}

.icon-ui-04-2::before {
  content: "\eac2";
}

.icon-filter::before {
  content: "\eac3";
}

.icon-infinite::before {
  content: "\eac4";
}

.icon-infinite-2::before {
  content: "\eac5";
}

.icon-home-minimal::before {
  content: "\eac6";
}

.icon-home-minimal-2::before {
  content: "\eac7";
}

.icon-filter-2::before {
  content: "\eac8";
}

.icon-cloud-25::before {
  content: "\eaca";
}

.icon-cloud-25-2::before {
  content: "\eacb";
}

.icon-single-02::before {
  content: "\eacc";
}

.icon-single-02-2::before {
  content: "\eacd";
}

.icon-users-wm::before {
  content: "\eace";
}

.icon-users-wm-2::before {
  content: "\eacf";
}

.icon-camera-flash::before {
  content: "\ead0";
}

.icon-camera-flash-2::before {
  content: "\ead1";
}

.icon-ic_file_download_48px::before {
  content: "\ead2";
}

.icon-ic_thumb_up_48px::before {
  content: "\ead3";
}

.icon-ic_thumb_down_48px::before {
  content: "\ead4";
}

.icon-hourglass::before {
  content: "\ead5";
}

.icon-hourglass-2::before {
  content: "\ead6";
}

.icon-time-3-2::before {
  content: "\ead7";
}

.icon-time-3-3::before {
  content: "\ead8";
}

.icon-chat-46-2::before {
  content: "\ead9";
}

.icon-laptop-1::before {
  content: "\eada";
}

.icon-laptop-1-2::before {
  content: "\eadb";
}

.icon-grid-45::before {
  content: "\eadc";
}

.icon-grid-45-2::before {
  content: "\eadd";
}

.icon-layout-11::before {
  content: "\eade";
}

.icon-layout-11-2::before {
  content: "\eadf";
}

.icon-sidebar::before {
  content: "\eae0";
}

.icon-sidebar-2::before {
  content: "\eae1";
}

.icon-email-84::before {
  content: "\eae2";
}

.icon-email-84-2::before {
  content: "\eae3";
}

.icon-menu-right::before {
  content: "\eae4";
}

.icon-menu-left::before {
  content: "\eae5";
}

.icon-menu-34::before {
  content: "\eae6";
}

.icon-menu-dots-2::before {
  content: "\eae7";
}

.icon-minimal-left::before {
  content: "\eae8";
}

.icon-minimal-right::before {
  content: "\eae9";
}

.icon-stre-right::before {
  content: "\eaea";
}

.icon-stre-left::before {
  content: "\eaeb";
}

.icon-menu-left-2::before {
  content: "\eaec";
}

.icon-menu-right-2::before {
  content: "\eaed";
}

.icon-menu-34-2::before {
  content: "\eaee";
}

.icon-triangle-right-17-2::before {
  content: "\eaef";
}

.icon-puzzle-10-2::before {
  content: "\eaf0";
}

.icon-logo-facebook::before {
  content: "\eaf1";
}

.icon-logo-twitter::before {
  content: "\eaf2";
}

.icon-logo-linkedin::before {
  content: "\eaf3";
}

.icon-logo-pinterest::before {
  content: "\eaf4";
}

.icon-instant-camera-2::before {
  content: "\eaf5";
}

.icon-logo-instagram::before {
  content: "\eaf6";
}

.icon-logo-dribbble::before {
  content: "\eaf7";
}

.icon-logo-skype::before {
  content: "\eaf8";
}

.icon-logo-messenger::before {
  content: "\eaf9";
}

.icon-logo-slack::before {
  content: "\eafa";
}


body {
  font-family: 'Inter', sans-serif;
}

.table td {
  padding: 0px !important
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d1cccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgb(67 34 212 / 25%);
}

.btn:focus,
.btn.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgb(67 34 212 / 25%);
}



/* General Header Styles */
.menu-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(12px) saturate(100%);
  backdrop-filter: blur(10px) saturate(100%);
  transition: border-bottom 0.15s, top 0.25s, background-color 0.3s;
}

/* Container Style */
.menu-header .container {
  display: flex;
  justify-content: center;
}

/* Navbar Styles */
.navbar-custom {
  border: 1px solid #292929;
  margin-top: 20px;
  border-radius: 15px;
  padding: 5px 10px !important;
  position: fixed;
  top: 0;
}

/* Navbar Brand Styles */
.navbar-brand-custom {
  margin: 0;
}

/* Navbar Button Styles */
.navbar-toggler-custom {
  border: none;
}

.friendsupw {
  text-align: -webkit-center;
  display: block;
  width: 100%;
}

/* Nav Link Styles */
.nav-link-custom {
  color: white;
}

.nav-link-custom.active {
  font-weight: bold;
}

/* Contact Button Styles */
.btn-contact {
  border-radius: 10px;
  background: #2f2f2f !important;
  color: white !important;
}



/* Section Intro Styles */
.intro {
  display: block;
  z-index: 999;
  position: relative;
  background: black;
  padding-top: 0 !important;
  margin-top: 9rem !important;
}

.intro .container {
  text-align: center;
}

/* Heading and Subtitle Styles */
.intro .heading-wrapper {
  width: 429px;
}

.intro .heading {
  letter-spacing: -0.03em;
  background: linear-gradient(180deg, #ffffff 30%, #95959580 100%), #0f172a;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 3rem;
}

.intro .subtitle {
  font-size: 1rem !important;
}

/* Product Wrapper Styles */
.product-wrapper {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0) 100%);
}

small,
.small {
  font-size: 12.8px !important;
}

.table-custom {
  min-height: 30px;
  margin-top: 7px;
  font-size: 13px;
  max-width: 446px !important;
  margin: 0 auto;
  width: 446px !important;
}

.table-custom td {
  padding-bottom: 0;
  padding-top: 0;
  text-align: right;
  vertical-align: middle;
  border-top: 0;
}

/* Form Group Styles */
.form-group-custom {
  height: 45px;
}

.form-control-custom {
  color: #f2f2f2 !important;
  opacity: 1;
  background: #2f2f2f !important;
  border: white;
  padding: 12px 20px;
  border-radius: 12px;
}

/* Button Styles */
.btn-custom {
  margin-top: 0;
  padding: 12px 20px;
  border-radius: 12px;
  color: white;
  background-image: linear-gradient(to bottom right, #071a4a, #4521d9) !important;
  border: white;
}

.label-error {
  font-size: 14px;
  color: red;
  font-family: Arial;
}

.application-button {
  display: inline-block;
  text-decoration: none !important;
  background: black !important;
}

/* Footer Styles */
.footer-info {
  text-align: center;
  display: block;
  width: 100%;
}

.footer-info .contact-info {
  width: 429px;
}

.footer-info .contact-number {
  font-size: 1rem !important;
  color: white;
  opacity: 1;
}

.contactno {
  font-size: 0.8rem !important;
  margin-top: 60px;
  margin-bottom: 0;
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.75rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: inherit;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #4b5563;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container> :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}

.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: inherit;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: #d2d4e4;
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.75rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: subtract(0.75rem, 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: #d2d4e4;
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.67;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: inherit;
  --bs-modal-footer-border-color: #d2d4e4;
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #24214b;
  --bs-backdrop-opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer>* {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.toastmsg {
  position: fixed;
  z-index: 1011;
  bottom: 80px;
  right: 20px;
  background-image: linear-gradient(to bottom right, #071a4a, #4521d9) !important;
}

.min-h-screen {
  min-height: 100vh;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(95, 56, 249, var(--bs-bg-opacity, 1)) !important;
}

.fade.show {
  opacity: 1;
}

.btn-customindex:hover {
  color: white;
}

.w-full {
  width: 100%;
  background: white !important;
}

.flex-col {
  flex-direction: column;
}

.flex {
  display: flex;
}

.min-h-screen {
  min-height: 100vh;
}

.justify-between {
  justify-content: space-between;
}

.w-full {
  width: 100%;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.flex {
  display: flex;
}

@media (min-width: 768px) {
  .md\:max-w-lg {
    max-width: 32rem;
  }
}

@media (min-width: 1024px) {
  .lg\:max-w-xl {
    max-width: 36rem;
  }
}

.w-full {
  width: 100%;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

@media (min-width: 768px) {
  .md\:max-w-lg {
    max-width: 32rem;
  }
}

@media (min-width: 1024px) {
  .lg\:max-w-xl {
    max-width: 36rem;
  }
}

.items-center {
  align-items: center;
}

.birdview {
  display: flex;
  width: 100%;
  justify-content: start;
}

.birdview-div {
  width: 90px;
  height: 90px;
  border-radius: 8px;
  text-align: -webkit-center;
  margin: 5px;
}

.birdview-success {
  background: #44b162;
}

.birdview-img {
  width: 50px;
  display: flex;
  filter: brightness(0) invert(1);
  margin-top: 6px;
  margin-bottom: 6px;
}

.birdview-span {
  display: block;
  height: 28px;
  font-size: 13px;
  text-transform: uppercase;
  padding: 3px 0px;
  border-radius: 0px 0px 8px 8px;
}

.birdview-span-success {
  background: #159b38;
  color: white;
}

.whiteimage {
  filter: brightness(0) invert(1);
}

.media-md {
  font-size: .7916666667rem;
  height: 2.375rem;
  width: 2.375rem;
}

.imgback {
  padding: 8px;
  display: inline-block;
  border-radius: 5px;
}

.bgcodeblue {
  background: #0097fe;
}

.bgescalation {
  background: #b31c45;
}


.bgtoilet {
  background: #af32e6;
}


.bgextra {
  background: #7ec543;
}


.bgnurse {
  background: #d63547;
}


.bghouse {
  background: #f08429;
}

.spanbed {
  font-weight: 600;
}

.birdview-span-success {
  background: #18b542
}

.birdview-success {
  background: #149C39;
}

.birdview-failed {
  background: #DD2526;
}

.birdview-span-failed {
  background: #ff3233;
  color: white;
}

.bird-total {
  display: flex;
  align-items: center;
}

.bird-red {
  background: #DD2526;
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 5px;
}

.bird-text {
  display: inline-block;
  margin-left: 6px;
}

.bird-green {
  background: #149C39;
  margin-left: 12px;
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 5px;
}

.bottombtn {
  justify-content: start !important;
}

.liveth {
  background: transparent !important
}

.borderno {
  border-bottom: 1px solid #e7e7e7 !important;
}

.borderbottom {
  border-bottom: 1px solid #ededed !important;
}


.borderbottomback1 td {
  color: #0097fe !important;
  background-color: #0097fe17 !important;
}

.borderbottomback1 td .media-text .title {
  color: #0097fe !important
}


.back1 {
  background: #0097fe !important
}


.borderbottomback2 td {
  color: #7dc442 !important;
  background-color: #7dc44217 !important;
}

.borderbottomback2 td .media-text .title {
  color: #7dc442 !important
}

.back2 {
  background: #7dc442 !important
}


.borderbottomback3 td {
  color: #d63547 !important;
  background-color: #d6354717 !important;
}

.borderbottomback3 td .media-text .title {
  color: #d63547 !important
}

.back3 {
  background: #d63547 !important
}

.borderbottomback4 td {
  color: #f08429 !important;
  background-color: #f0842917 !important;
}

.borderbottomback4 td .media-text .title {
  color: #f08429 !important
}

.back4 {
  background: #f08429 !important
}

.borderbottomback5 td {
  color: #af32e6 !important;
  background-color: #af32e617 !important;
}

.borderbottomback5 td .media-text .title {
  color: #af32e6 !important
}

.back5 {
  background: #af32e6 !important
}

.borderbottomback6 td {
  color: #4cb050 !important;
  background-color: #4cb05017 !important;
}

.borderbottomback6 td .media-text .title {
  color: #4cb050 !important
}

.back6 {
  background: #4cb050 !important
}

.borderbottomback7 {
  color: #b31c45 !important;
  background-color: #b31c4517 !important;
}

.borderbottomback7 .media-text .title {
  color: #b31c45 !important
}

.back7 {
  background: #b31c45 !important
}

.borderbottomback8 {
  color: #f44236 !important;
  background-color: #f4423617 !important;
}

.borderbottomback8 .media-text .title {
  color: #f44236 !important
}

.back8 {
  background: #f44236 !important
}

.btleft {
  margin-left: 5px !important;
  display: block !important;
}

.live-img {
  width: 40px;
  display: flex;
  filter: brightness(0) invert(1);
  margin-top: 7px;
  margin-left: 7px;
}

.bottombtnjustify {
  justify-content: space-between !important;
}

.font156 {
  font-size: 16px;
  font-weight: 600;
}

.oncall {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  background: white;
  border: 1px solid #dadada;
  align-items: center;
  padding: 8px 10px;
  gap: 10px;
}

.oncall1 {
  background: #149c39;
  height: 53px;
  width: 60px;
  border-radius: 8px;
}

.oncall2 {
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  /* flex-wrap: nowrap; */
  /* align-items: stretch; */
  width: 100%;
}

.oncallstatus {
  background: #DD2526;
  padding: 3px 8px;
  color: white;
  border-radius: 6px;
}

.textright {
  text-align: right;
}

.bottombuttonlive {
  padding-top: 5px !important;
}

/* .nk-menu-subactive
{
    
    box-sizing: border-box !important;
    display: block !important
} */

.nk-menu-toggle1 {
  position: relative;
}

.nk-menu-toggle1::before {
  position: absolute;
  font-family: "Nioicon";
  top: 50%;
  content: "";
  right: 1.5rem;
  transform: translateY(-50%);
  font-size: 18px;
  transition: 0.4s;
}

.has-sub.active>.nk-menu-toggle1::before {
  transform: translateY(-50%) rotate(-180deg);
}

/* .nk-menu-toggle1+.nk-menu-sub {
    display: none;
} */
.btn-icon.btn-md {
  width: 100%;
  gap: 7px;
}

.bottomname {

  display: flex;
  align-items: center;
  padding: 9px 0px;
}

.bottomtitle {

  display: flex;
  flex-direction: column;
}

.marginright12 {

  margin-right: 12px;
}

.font-size12 {
  font-size: 12px;
}



.paddingtop10 {
  padding-top: 10px;
}

.nk-menu-link {
  color: black !important;

  align-items: center !important;
}

.maindiv {
  display: block;
  width: 100%;
  padding-top: 10px;
}

.displayinlineblock {
  display: inline-block;
}

.right-float {
  width: 150px;
  float: right;
  height: 35px !important;
  font-size: 14px;
  text-align: right;
  margin-top: 0px !important;
}

.nk-menu-link-custom {
  margin: 0px !important;
  flex-direction: row-reverse;
  gap: 12px;
  padding: 0px !important;
}

.media-margin-right {
  margin-right: 12px;
}

.underline-text {
  text-decoration: underline;
  margin-top: -5px;
}

/* Wrapper */
.custom-wrapper {
  width: 100%;
  font-size: 14px;
  margin-top: 20px;
}

/* Border */
.custom-border-1 {
  border-bottom: 1px solid #dadada;
  height: 30px;
}

/* Button 1 */
.custom-button-1 {
  display: inline-block;
  height: 30px;
  margin-right: 25px;
  border-bottom: 3px solid #000000;
  color: black !important;
  cursor: pointer;
  font-weight: 500;
}

/* Button 2 */
.custom-button-2 {
  display: inline-block;
  height: 30px;
  cursor: pointer;
  margin-right: 25px;
  color: black !important;
  font-weight: 500;
}

/* Button 3 */
.custom-button-3 {
  display: inline-block;
  height: 30px;
  margin-right: 25px;
  cursor: pointer;
  color: black !important;
  font-weight: 500;
}

/* Text */
.custom-text-1,
.custom-text-2,
.custom-text-3 {
  color: inherit;
}

/* Content */
.custom-content {
  display: block;
  width: 100%;
}

/* Title */
.custom-title {
  display: inline-block;
  margin-bottom: 15px;
  color: black;
  font-weight: 500;
}

/* Course Container */
.custom-course {
  display: flex;
  gap: 15px;
  border: 1px solid #dadada;
  padding: 10px 15px;
  border-radius: 5px;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

/* Image */
.custom-image {
  /* Styles for image container */
}

/* Course Details */
.custom-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

/* Course Title */
.custom-course-title {
  display: block;
  font-size: 14px;
  color: black;
  font-weight: 500;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* Course Description */
.custom-course-description {
  display: block;
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* Buttons Container */
.custom-buttons {
  cursor: pointer;
  display: block;
  text-wrap: pretty;
  line-height: 20px;
  font-size: 13px;
  margin-top: 5px;
}

/* Tag */
.custom-tag {
  padding: 2px 8px;
  display: inline-block;
  border: 1px solid #dadada;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer
}

.custom-enroll {
  padding: 2px 8px;
  display: inline-block;
  border: 1px solid #dadada;
  border-radius: 5px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer
}

/* Enroll Button */
.custom-enroll {
  float: right;
  background: black;
  color: white;
}

.imgtags {
  width: 35px;
  height: 123px;
  object-fit: cover;
  border: none !important;
  background: #6e2321 !important;
}

.media-margin-right {
  margin-right: 0px;
}

.fontsize42 {
  font-size: 42px;
  color: black;
}

.inrgreen {
  float: right;
  font-weight: 600;
  font-size: 14px;
  color: #149c39;
}

.inrred {
  float: right;
  color: #dd2526;
  font-weight: 600;
  font-size: 14px;
}

.transleft {
  border: none;
  padding-left: 15px;
  font-weight: 500;
  cursor: default !important;
}

.transright {
  border: none;
  padding-right: 15px;
  background: none !important;
  color: black;
  cursor: default !important;
  font-weight: 500;
  max-width: 55%;
  text-align: right;
}

.transitem {
  border-top: 1px solid #dadada;
  padding-top: 8px;
  cursor: default !important;
}

.transmain {
  padding: 8px 0px;
  cursor: default !important;
}

.sround {
  text-align: center;
  display: block;
  margin-top: 15px;
}

.scirclegreen {
  width: 50px;
  height: 50px;
  text-align: center;
  display: inline-block;
  background: #149c39;
  border-radius: 50%;
}

.scirclered {
  width: 50px;
  height: 50px;
  display: inline-block;
  background: #dd2526;
  border-radius: 50%;
}

.scircleicon {
  color: white;
  font-size: 28px;
  line-height: 49px;
}

.tmain {
  display: block;
  text-align: center;
  margin-top: 10px;
}

.tsuccess {
  display: block;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 15px;
}

.tamount {
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-left: -16px;
  line-height: 10px;
}

.cancleicon {
  float: right;
  font-size: 22px;
  width: 40px;
  height: 24px;
  text-align: right;
  cursor: pointer
}

.startitle {
  font-weight: 400;
  color: #3b3b3b;
}

.startop {
  display: block;
  margin-top: 8px;
}

.staricon {
  font-size: 42px;
  display: inline-block;
  cursor: pointer !important;
}

.starblank {
  color: #3b3b3b;
}

.startopdescription {
  display: block;
  margin-top: 15px;
}

.width100 {
  width: 100%;
}

.canceliconin {
  vertical-align: super;
}

.custome-tagpad {
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer
}

.slotname {
  display: block;
  line-height: 20px;
}

.displayblock {
  display: block;
}

.visitwebsite {
  text-decoration: underline;
  font-weight: 600;
  color: #6e2320;
  cursor: pointer
}

.confirmbook {
  border-top: 1px solid #dadada;
  padding-top: 15px;
  margin-bottom: 15px;
}

.positionrelative {
  position: relative;
}

.slotgreen {
  background: #e98000;
  margin-left: 12px;
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 5px;
}

.slotgreencolor {
  background: #e98000;
  color: white;
}

.slotred {
  background: #702422;
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 5px;
}

.slotredcolor {
  background: #702422;
  color: white;
}

.slotselected {
  background: #149c39 !important;
  color: white !important;
}

.logocss {

  color: transparent;
  width: auto;
  height: 44px;
  border-radius: 8px;
}

.bottomfix {
  position: sticky;
  bottom: 0px;
  background: white;
  width: 100%;
  text-align: center;
  padding: 15px 0px;
  border-top: 1px solid #dadada;
  font-size: 14px;
  font-weight: 500;
}

.custome-tagpad:hover {
  background: #149c39 !important;
  color: white !important;
}

.custom-coursenopoint {

  cursor: default !important;
}

.selected {
  background-color: black;
  color: white;
}

.custom-enroll-amount {
  font-size: 16px;
  font-weight: 600;
  color: black;
  float: right;
  display: block;
  width: 100%;
  text-align: right;
}

.custom-enroll-amount-title {
  font-size: 12px;
  font-weight: 600;
  color: black;
  float: right;
  display: block;
  width: 100%;
  text-align: right;
}

.txtbox {

  width: 135px !important;
  margin-bottom: 5px;
  display: inline-block;
  margin-right: 5px;
}

.txtspan {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: block;
}

.txtspan1 {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: block;
  color: #ff2f2f;
}

.attcss {
  font-size: 13px;
  color: #3b3b3b
}

.curserset {
  cursor: pointer;
}


.userinfo {
  display: inline-flex;
  gap: 8px;
  border: 1px solid #dadada;
  padding: 8px 15px 8px 8px;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 10px;
  box-sizing: border-box;
  cursor: pointer;
  margin-right: 10px;
}

.userphoto {

  width: 50px;
  height: 50px;
  object-fit: cover;
  border: none !important;
  padding: 0px !important;
  background: none !important;
}


.feesinfo {
  border-top: 1px solid #dadada;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  margin-top: 15px;
}


.tamount1 {
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-left: 0px;
  line-height: 10px;
  margin-top: 5px;
  margin-bottom: 4px;
}

.custom-course-description1 {
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  margin-bottom: 5px;
  -webkit-box-orient: vertical;
}

.left15 {
  margin-left: 15px;
  color: gray;
}

.margin10bottom {

  display: block;
  font-weight: 500;
  margin-bottom: 10px;
}

.inrgreen1 {
  font-weight: 600;
  font-size: 15px;
  color: #149c39;
}

.marginbottom0 {
  margin-bottom: 0px !important
}

.custom-tag-cursor {
  cursor: default !important;
}

.selectedstarcolor {
  color: #3e9729
}

.starticonline {

  font-size: 15px;
  line-height: 21px;

}

.marginleft10 {
  margin-left: 10px;
}

.maintitle {

  max-width: 80%;
  display: inline-block;
}


@media only screen and (max-width: 767px) {
  .px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .maindiv {

    padding-top: 20px;
  }

  .footerdisplay {
    display: block !important;
  }

  .footercenter {
    justify-content: center !important;
  }

  .lg\:mt-9 {
    margin-top: 1.25rem;
  }

  .lg\:space-y-6> :not([hidden])~ :not([hidden]) {
    margin-top: 10px;
  }

  .logtitle {
    font-weight: 500 !important;
    line-height: 1.8rem !important;
    font-size: 1.2rem !important;
  }

  .custom-wrapper {
    font-size: 13px;
    margin-top: 25px;
  }
}

.is-theme .nk-menu .nk-menu-link {
  cursor: pointer;
}

.media-img-new {
  border-radius: inherit;
  border: none !important;
  background: white;
  padding: 0px;
}

.setcolorblack {
  color: black !important;
}

.setbuttonhover {
  top: 40px;
  right: 14px;
}


.image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.preview-item {
  position: relative;
  max-width: 150px;
  max-height: 150px;
  object-fit: cover;
  overflow: hidden;
  border: 1px solid rgb(223, 223, 223);
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview-item button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: black;
  color: #fff;
  border: none;
  padding: 2px 6px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
}

.preview-item button:hover {
  background-color: #cc0000;
}

.pointercursor {
  cursor: pointer;
}

input[type="radio"]:checked {
  background-color: #93e026 !important;
}

.spanradio {

  margin-left: 10px;
  line-height: 26px;
  vertical-align: bottom;
}

.spanradioleft {

  margin-left: 20px;
}

.radiodiv {

  margin-bottom: 15px;
}

/* .dropdown:hover .dropdown-menu-end {
    display: block;
} */

.actionbtn {

  margin-right: 8px;
  margin-bottom: 5px;
}

.actioni {
  font-size: 20px;
}

.nk-menu-link {
  align-items: self-start;
  background-color: #0000;
  color: #2e314a;
  display: flex;
  font-size: .875rem;
  font-weight: 500;
  line-height: 20px;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  padding: 1rem .75rem;
  position: relative;
}

.media-circle {
  border-radius: 50rem;
}

.media img {
  border-radius: 8px;
}

.img-fluid,
.img-thumbnail {
  height: auto;
  max-width: 100%;
  border: none !important;
  padding: 0px !important;
}

.nk-menu-text {
  display: inline-block;
  flex-grow: 1;
  white-space: nowrap;
}

.underline-text {
  text-decoration: underline;
  margin-top: -5px;
}

.font-size12 {
  font-size: 12px;
}

.norecordcss {
  width: 100%;
  text-align: center;
  min-height: 400px;
}

.norecordfont {
  font-size: 15px;
  font-weight: 600;
  color: black;
}

.media {
  border-radius: .25rem;
  flex-shrink: 0;
  font-size: .9166666667rem;
  height: 2.75rem;
  width: 2.75rem;
}

.booklogo {
  color: black;
  font-weight: 800;
  letter-spacing: -0.55px;
  padding-left: 5px;
  font-size: 24px;
  vertical-align: middle;
}







.custom-coursej {
  display: flex;
  gap: 10px;
  border: 1px solid #dadada; 
  border-radius: 5px;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.input-container {
  display: flex;
  max-width: 100%;
  gap: 10px;
  margin-bottom: 15px;
}

.form-control.txtbox {
  flex: 1;
  min-width: 0;
}

.custom-buttonsj {
  cursor: pointer;
  display: block;
  text-wrap: pretty;
  line-height: 20px;
  font-size: 13px;
  margin-top: 4px;
}

.imgtagsj {
  width: 72px;
  height: 72px;
  object-fit: cover;
  border: none !important;
  background: none !important;
  padding: 0px;
}

.badgecss {
  width: 18px;
  display: inline-block;
  vertical-align: sub;
}

.searchbtn {
  height: 35px;
  padding: 0px 15px;
}

.left5 {

  margin-left: 5px;
}

.displayflex {

  display: flex;
}

.height29 {

  height: 29px !important;
}

.displayflex .select2-container--default .select2-selection--single {
  min-width: 150px;
  /* Adjust the minimum width as needed */
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  /* Adjust spacing between tags as needed */
}

.tag-item {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  /* Light gray background */
  border-radius: 16px;
  /* Rounded corners */
  padding: 5px 10px;
  /* Padding inside the tag */
  font-size: 14px;
  /* Adjust font size */
  color: #333;
  /* Text color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional shadow */
}

.tag-text {
  margin-right: 8px;
  /* Space between text and delete button */
}

.tag-delete-button {
  background: none;
  border: none;
  color: #ff4d4d;
  /* Red color for delete button */
  font-size: 16px;
  /* Adjust font size for delete button */
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.tag-delete-button:hover {
  color: #cc0000;
  /* Darker red on hover */
}

.rdobtn {
  margin-right: 8px;
  vertical-align: middle;
}

.custom-enrollblock {
  float: right;
  color: white;
}

.custom-enrollblock {
  padding: 2px 8px;
  display: inline-block;
  border: none !important;
  border-radius: 5px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.block-button {
  background-color: #149c39;
  /* Example color for 'Block' */
}

.unblock-button {
  background-color: #dd2526;
  /* Example color for 'Unblock' */
}
.custom-coursej {
  display: flex;
  gap: 10px;
  border: 1px solid #dadada;
  border-radius: 5px;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  box-sizing: border-box;
  cursor: pointer;
}


.custom-image1 {
  /* Ensure no padding around the image */
  padding: 0; 
  width:90px;
  height: 90px;
}

.custom-details-wrapper {
  /* Add padding only to the details section */
  padding: 10px 20px 10px 10px;
  flex-grow: 1;
}

.custom-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Ensure no additional margin/padding affects the details */
} 
.cancleicon { 
  float: right;
  font-size: 22px;
  width: 40px;
  height: 24px;
  text-align: right;
  cursor: pointer;
  color: white;
  background: black;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  margin-right: 6px;
  line-height: 27px;
  padding: 0px 10px;
}
.margin20{ 
  margin-bottom: 20px;
}